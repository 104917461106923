<template>
  <div>
    <div v-if="elementData.uuid">
      <meet
        :options="computedJitsiOptions"
      />
    </div>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import Meet from './Meet.vue'
import elementStoreModule from '../storeModule'

export default {

  components: {
    Meet,
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      elementData: { uuid: undefined, patient_name: undefined },
    }
  },
  computed: {
    computedJitsiOptions() {
      if (!this.elementData.uuid) {
        // UUID not available, return empty options
        return {}
      }
      // UUID available, generate Jitsi Meet options
      return {
        roomName: `avenir/${this.elementData.uuid}`,
        noSSL: false,
        userelementData: {
          email: 'user@email.com', // TODO: get from user
          displayName: this.elementData.patient_name,
        },
        height: '600px',
        width: '100%',
      }
    },
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'meet'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-m-pu-${this.RESOURCES_ELEMENT_NAME}`
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, elementStoreModule)

    const { case_uuid } = router.currentRoute.params
    const ans = await this.get_uuid(case_uuid)
    this.elementData.uuid = ans.uuid
    this.elementData.patient_name = ans.patient_name
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    async get_uuid(case_uuid) {
      return store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/get_uuid`, { case_uuid })
        .then(response => response.data)
        .catch(error => {
          console.log(error)
          return undefined
        })
    },
  },

}
</script>
