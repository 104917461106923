import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEditionTime(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/process/edition_time/1')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/user', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_case_date(ctx, { meet_uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/process/case_date/meet_uuid/${meet_uuid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_lab_tests(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/labtest', {
            params: params.queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_arls(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', {
            params: {
              perPage: 500, page: 1, companyType: 'ARL', only_active: true,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_medicines(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/service/medicines', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_medicines_complete(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/service/medicines/complete')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    /** this method returns the uuid of a meeting for the public access */
    get_uuid(ctx, { case_uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/process/meet/${case_uuid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generate_final_document(ctx, {
      meet_uuid, template_type, notification_destination, destination_folder,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/case/meet/document/${meet_uuid}/${template_type}/${notification_destination}/${destination_folder}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_services(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/service', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_headquarters(ctx, { company_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/company/headquarter/${company_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_companies(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    /** this method returns the case object of a meeting for the private access */
    fetch_element(ctx, { meet_uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/case/meet/${meet_uuid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchImage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/core/image/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_key_value(ctx, { type, deep, parent }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/core/type_value/${type}`, {
            params: { deep, parent },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save_element(ctx, { meet_uuid, elementData }) {
      console.log('saving parameters:', meet_uuid, elementData)
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/cases/case/meet/${meet_uuid}`, elementData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save_edition_time(ctx, { meet_uuid, edition_user_id }) {
      console.log('saving parameters:', meet_uuid, edition_user_id)
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/cases/case/edition_time/${meet_uuid}/${edition_user_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
