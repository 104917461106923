<template>
  <div ref="jitsiContainer" style="height: 100%; width: 100%;"></div>
</template>

<script>
export default {
  props: {
    domain: {
      type: String,
      default: 'meet.avenirsalud.com'
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    retries: {
      type: Number,
      default: 5
    },
    retryTimeout: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      jitsiApi: null,
      uuid: null,
    }
  },
  mounted() {
    this.loadScript('https://meet.avenirsalud.com/external_api.js', async () => {
      if (!window.JitsiMeetExternalAPI) throw new Error('Jitsi Meet API not loaded')
      this.tryEmbedJitsiWidget()
    });
  },
  beforeDestroy() {
    this.removeJitsiWidget();
  },
  methods: {
    loadScript(src, cb) {
      const scriptEl = document.createElement('script')
      scriptEl.src = src
      scriptEl.async = 1
      document.querySelector('head').appendChild(scriptEl)
      scriptEl.addEventListener('load', cb)
    },
    tryEmbedJitsiWidget() {
        const options = {
          ...this.options,
          parentNode: this.$refs.jitsiContainer,
        }
        this.jitsiApi = new window.JitsiMeetExternalAPI(this.domain, options)
    },
    removeJitsiWidget() {
      if (this.jitsiApi) this.jitsiApi.dispose();
    },
  }
};
</script>